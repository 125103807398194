<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form
            ref="logForm"
            v-model="valid" >
            <v-row>
                <v-col>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    :loading="loading"
                    v-model="form.name"
                    :counter="30"
                    :rules="[rules.required]"
                    :label="$store.getters.getTextMap().name"
                    required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    :loading="loading"
                    v-model="form.primary_field_label"
                    :counter="10"
                    :rules="[rules.required]"
                    :label="$store.getters.getTextMap().primary_field_label"
                    required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.primary_field_type"
                            :items="fieldTypes"
                            :label="$store.getters.getTextMap().primary_field_type"
                            
                            item-text="label"
                            item-value="value"
                            required
                            :rules="[rules.required]"
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                </v-col>
                <v-col v-if="form.primary_field_type && form.primary_field_type =='list'">
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                        <v-autocomplete  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.primary_field"
                            :items="fieldMetas"
                            :label="$store.getters.getTextMap().primary + form.primary_field_type "
                            
                            item-text="label"
                            item-value="value"
                            :rules="[rules.required]"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-autocomplete>
                    </v-col>
                    
            </v-row>
        </v-form>
            <v-divider></v-divider>
            
            <v-divider></v-divider>
            <v-row>
                <v-col>
                    <v-data-table
        :headers="fieldHeaders"
        :items="metaItems"
        item-key="label"
        :search="search"        
        :loading="loading"
        :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $store.getters.getTextMap().additional_fields }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            
            <v-form
                    ref="fieldForm"
                    v-model="fieldValid" >
            <v-row dense >
                
            
                

                                    
                                        <v-col align-self="center">
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    :loading="loading"
                    v-model="fieldForm.label"
                    :counter="10"
                    :rules="[rules.required]"
                    :label="$store.getters.getTextMap().field_label"
                    required
                    ></v-text-field>
                </v-col>
                                    <v-col align-self="center">
                    <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
           v-if="!loading"
                            v-model="fieldForm.field_type"
                            :items="fieldTypes"
                            :label="$store.getters.getTextMap().field_type"
                            
                            
                            item-text="label"
                            item-value="value"
                            required
                            :rules="[rules.required]"
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                                    </v-col>
                <v-col align-self="center" v-if="!loading && fieldForm.field_type && fieldForm.field_type =='list'">
                      
                        <v-autocomplete  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading && fieldForm.field_type && fieldForm.field_type =='list'"
                            v-model="fieldForm.field"
                            :items="fieldMetas"
                            :label="$store.getters.getTextMap().choose_list"
                            
                            item-text="label"
                            item-value="value"
                            :rules="[rules.required]"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-autocomplete>
                </v-col>
                <v-col  align-self="center">
                    <v-btn small @click="addField" :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().submitbtnColor" >
                                         {{ $store.getters.getTextMap().add }}<v-icon small>mdi-plus</v-icon>
                                        </v-btn>
                </v-col>
                                
                
                
            </v-row>
        </v-form>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon small @click="removeField(item)" :dark="$store.getters.getColorPalette().isDark" color="red">mdi-delete</v-icon>
            
        </template>
        
      </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn small @click="onSubmit" :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().submitbtnColor" >
                                          {{ $store.getters.getTextMap().submit }}<v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                </v-col>
                <v-col>
                    <v-btn small @click="$emit('close')" :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().cancelbtnColor" >
                                           {{ $store.getters.getTextMap().cancel }} <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                </v-col>
            </v-row>
        
    </v-container>
</template>
<script >
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default{
    name:'CreateDynamicLog',
    props:['dynamicLog'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.dynamicLog){
            this.endpoint='updateDynamicLog'
            this.form=Object.assign({},this.dynamicLog)
            this.meta=Object.assign({},this.dynamicLog.meta)
        }else{
            this.endpoint='createDynamicLog'
        }

    },
    data(){
        return {
            showDismissibleAlert:false,
            info:'',
            loading:false,
            valid:false,
            fieldValid:false,
            search:null,
            endpoint:'createDynamicLog',
            fieldHeaders:[
                    {
                text: "Field Name",
                value: "label",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "Field Type",
                value: "type",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "Additional Info",
                value: "field",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                    {
                text: "Action",
                value: "action",
                class:
                    " text-capitalize font-weight-black " +
                    this.$store.getters.getColorPalette().tableHeaderColorName,
                },
            ],
            meta:{},
            form:{
                meta:{}
            },
            fieldForm:{},
            rules:FormRules.rules,
            fieldTypes:[
              {label:this.$store.getters.getTextMap().list,value:'list'},
              {label:this.$store.getters.getTextMap().text,value:'text'},
            ],
        }
    },
    computed:{
        fieldMetas(){
          let op=[]
          
            let lsts=this.$store.state.lists
            for(let i of lsts){
              op.push({
                label:i.name,
                value:i.list_id
              })
            }
          return op
        },
        metaItems(){
            if(this.meta){
                return Object.values(this.meta)
            }
            return []
        }
    },
    methods:{
        addField(){
            this.$refs.fieldForm.validate()
            if(this.fieldValid){
                // console.log('gh')

                this.meta[this.fieldForm.label]={
                    label:this.fieldForm.label,
                    type:this.fieldForm.field_type,
                    field:this.fieldForm.field
                }
                this.meta=Object.assign({},this.meta)
                // console.log(this.meta)

                this.$refs.fieldForm.reset()
                this.fieldValid=true
            }
            
        },
        removeField(field){
            if(field && field.label){
                delete this.meta[field.label]
                this.meta=Object.assign({},this.meta)
            }
        },
        onSubmit(){
            this.$refs.logForm.validate()
            if(this.valid){
                this.loading=true
                this.form['meta']=this.meta
                axios.post(this.$store.state.api+this.endpoint,this.form,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$store.dispatch('refreshDynamicLogs')
                        this.$emit('close')
                }
                })
                .catch(err=>{
                    console.error(err)
                    this.info=err
                    this.showDismissibleAlert=true
                this.loading=false});
                
            }
            this.loading=false
        }
    }
}
</script>