<template>
  <v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-card flat>
      <div class="px-5">
        <v-btn
          @click="downloadCsvWithHeaders"
          outlined
          :color="$store.getters.getColorPalette().downloadbtnColor"
          small
          >  {{ $store.getters.getTextMap().download_template }}</v-btn
        >
      </div>

      <v-card-title class="headline">{{ $store.getters.getTextMap().write_in_logbook_csv }} </v-card-title>
      <v-card-text>
        <template>
          <v-file-input
            v-model="file"
            show-size
            accept=".csv, .xlsx"
            @change="handleFileUpload"
            :label="$store.getters.getTextMap().upload_file"
          ></v-file-input>
          <v-alert v-if="check" type="error">{{ errorMessage }}</v-alert>
        </template>
      </v-card-text>

      <v-data-table :headers="tableHeaders" :items="tableData" item-key="index">
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
          text
          @click="onSubmit"
        >
        {{ $store.getters.getTextMap().write}}
        </v-btn>
        <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
          text
          @click="onReset"
        >
        {{ $store.getters.getTextMap().clear }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import InfoAlert from "@/components/InfoAlert";
import moment from "moment";
import axios from "axios";
import Papa from "papaparse";
import * as XLSX from "xlsx";

export default {
  name: "writeMultipleDynamicLog",
  props: {
    dynamicLog: {
      type: Object,
      required: true,
    },
  },
  components: {
    InfoAlert,
  },

  data() {
    return {
      file: null,
      loading: false,
      info: "",
      showDismissibleAlert: false,
      valid: false,
      errorMessage: "",
      check: false,
      tableHeaders: [],
      tableData: [],
      status: "",
      meta: [],
      primaryFields: [],
    };
  },

  mounted() {
    if (this.dynamicLog) {
      this.meta = Object.assign({}, this.dynamicLog.meta);
    }
  },

  methods: {
    downloadCsvWithHeaders() {
      if (!this.dynamicLog || !this.dynamicLog.meta) {
        console.error("Meta information is missing.");
        return;
      }

      const headers = Object.keys(this.dynamicLog.meta);
      headers.unshift(
        "From Date",
        "From Time",
        "To Date",
        "To Time",
        this.dynamicLog.primary_field_label
      );
      // console.log(headers);

      // const csv = headers.join(",") + "\n";
      const rowData1 = [
        "10-10-23",
        "1.00",
        "11-11-23",
        "12.00",
        "Primary",

      ];
      const rowData2 = [
        "11-11-23",
        "1.00",
        "12-12-23",
        "12.00",
        "Primary",
      ];




      const csvData = [headers, rowData1, rowData2];

      const csv = csvData.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "template.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    handleFileUpload() {
      if (!this.file) {
        return;
      }

      if (this.file.name.endsWith(".csv")) {
        this.parseCSVData();
      }

      if (this.file.name.endsWith(".xlsx")) {
        this.parseExcelData();
      }
    },

    parseExcelData() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);

        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the first sheet is the one you want to parse
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert the sheet data into JSON format
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Process the data similar to what you did for CSV
        this.primaryFields = [];
        this.tableHeaders = jsonData[0].map((header, index) => ({
          text: header.trim(),
          value: header.trim(),
          id: index,
          align: "center",
          class:
            "text-capitalize font-weight-black " +
            this.$store.getters.getColorPalette().tableHeaderColorName,
        }));

        let tempData = [];
        for (let i = 1; i <= jsonData.length - 1; i++) {
          tempData.push(jsonData[i]);
        }

        this.meta = [];
        this.primaryFields=[]
        tempData.forEach((row) => {
          let temp = {};
          if (row.length != 0) {
            this.tableHeaders.forEach((header, i) => {

              if(i==0 || i==2){
                row[i]=this.convertSerialToDate(row[i])
              }
              if(i==1 || i==3){
                row[i]=this.convertFractionalTimeToReadable(row[i])
              }

              if (i == 4) {
              this.primaryFields.push(row[i]);
            }

              if (i > 4) {
                // Start processing after the 4th column
                temp[header.value] = row[i] ? row[i] : "";
              }


            });

   

            this.meta.push(temp);


            const status = this.determineStatus(row);

         
            let temp1 = {}
        
            // console.log(row);
            
            row.map((data, index)=>{
              temp1[this.tableHeaders[index].value] = data
            })

            temp1["status"] = status

            this.tableData.push( temp1)
          }


          
          
        });
        console.log(this.tableData);


        this.tableHeaders.push({
          text: "Status",
          value: "status",
        });
      };

      reader.readAsArrayBuffer(this.file);
    },

    convertSerialToDate(serialNumber) {
      const baseDate = new Date(1899, 11, 30); // Base date used by Excel

      // Calculate the date by adding the number of days to the base date
      const date = new Date(
        baseDate.getTime() + serialNumber * 24 * 60 * 60 * 1000
      );

      // Extract day, month, and year components
      const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();

      // Concatenate components with "--" separator
      return `${day}/${month}/${year}`;
    },

    convertFractionalTimeToReadable(fractionalTime) {
      const totalSeconds = fractionalTime * 24 * 60 * 60;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      // const seconds = Math.floor(totalSeconds % 60);
      return `${hours}.${minutes.toString().padStart(2, "0")}`;
    },
    parseCSVData() {
      const reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;

        let rowData = data.split("\n");

        for (let k = 1; k <= rowData.length - 1; k++) {
          let array = rowData[k].split(",");
          let modifiedArray = array.map((element) => element.replace(/"/g, ""));
          modifiedArray.forEach((val, i) => {
            if (i == 4) {
              this.primaryFields.push(val);
            }
          });
        }


        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            // Set up headers
            this.tableHeaders = Object.keys(result.data[0]).map(
              (header, index) => ({
                text: header,
                value: header,
                id: index,
                align: "center",
                class:
                  " text-capitalize font-weight-black " +
                  this.$store.getters.getColorPalette().tableHeaderColorName,
              })
            );
            this.meta = [];

            // Process each row
            this.tableData = result.data.map((row) => {
              let temp = {};
              this.tableHeaders.forEach((header, i) => {
                if (i > 4) {
                  // Start processing after the 4th column
                  temp[header.value] = row[header.value]
                    ? row[header.value].trim()
                    : "";
                }
              });
              // Determine status and return updated row
              this.meta.push(temp);
              
              const status = this.determineStatus(row);


              console.log(row);

              return {
                ...row,
                status: status,
              };
            });


            console.log(this.tableData);

            // Add Status to table headers
            this.tableHeaders.push({
              text: "Status",
              value: "status",
            });
          },
        });
      };

      reader.readAsText(this.file);
    },

    removingSpaceInObjKeys(a) {
      const obj = {};
      for (const key in a) {
        const newKey = key.replace(/\s+/g, "");
        obj[newKey] = a[key];
      }
      return obj;
    },

    determineStatus(currentRow) {
      const allColumnsFilled = Object.values(currentRow).every(
        (value) => value !== "" && value !== "null" && value !== undefined
      );
      return allColumnsFilled
        ? "All Columns filled.so, you can submit"
        : "Some columns not filled.so, you can't submit";
    },

    onSubmit() {
      const submitPromises = this.tableData.map(async (item, i) => {
        let trimedObj = this.removingSpaceInObjKeys(item);
        let From_Timestamp = this.calculateTimeStamp(
          trimedObj.FromDate,
          trimedObj.FromTime
        );
        let To_Timestamp = this.calculateTimeStamp(
          trimedObj.ToDate,
          trimedObj.ToTime
        );
        trimedObj.from_timestamp = From_Timestamp;
        trimedObj.to_timestamp = To_Timestamp;
        trimedObj.meta_data = this.meta[i];
        trimedObj.primary_field_data = this.primaryFields[i];
        trimedObj.log_id = this.dynamicLog.log_id;

        let d = Object.assign({}, trimedObj);

        console.log(d);

        const isValid = Object.values(d).every((value) => {
          return value !== "" && value !== null && value !== undefined;
        });
        console.log(isValid);

        if (isValid) {
          this.loading = true;
          try {
            const response = await axios.post(
              this.$store.state.api + "writeDynamicLog",
              d,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.jwt,
                },
              }
            );

            if (response.data.status === "success") {
              console.log("inside the success");
              item.status = "Data Submitted";
            } else {
              item.status = "Data Not Submitted";
              console.log("outside the sucess");
            }
          } catch (err) {
            console.error(err);
          }
        } else {
          console.log("outside the submit api");
          item.status = "Data Not Submitted";
          this.loading = false;
        }
      });

      Promise.all(submitPromises)
        .then(() => {
          console.log("All submissions completed.");
        })
        .catch((error) => {
          console.error("Error during submission:", error);
        });
    },

    onReset() {
      (this.file = null), (this.loading = false);
      this.tableData = [];
      this.primaryFields = [];
    },

    calculateTimeStamp(a, b) {
      try {
        if (!a || !b) {
          console.error("Invalid date or time:", a, b);
          return null;
        }
        let dateTimeString = `${a} ${b}`;
        let timeStamp = moment(dateTimeString, "DD-MM-YYYY HH:mm");
        if (!timeStamp.isValid()) {
          console.error("Invalid date-time format:", dateTimeString);
          return null;
        }
        return Number(timeStamp.format("X"));
      } catch (error) {
        console.error("Error in calculateTimeStamp:", error);
        return null;
      }
    },
  },
};
</script>
<style scoped>
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
