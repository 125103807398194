<template>
  <v-container fluid
    :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-row>
      <v-col></v-col>
      <v-spacer></v-spacer>
      <v-col align="right"
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <span
          v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
          <v-icon small class="mr-2" @click="editDialog=!editDialog"
            v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            mdi-pencil
          </v-icon>
          <DeleteConfirmation v-on:confirm="deleteDynamicLog()" title="Delete Confirmation"
            description="Are you sure you want to delete this?">
            <v-icon small>
              mdi-delete
            </v-icon>
          </DeleteConfirmation>

        </span>
      </v-col>
    </v-row>
    <v-row v-if="editDialog">
      <v-col cols="12">
        <slot name="top"></slot>
        <CreateDynamicLog :dynamicLog="dynamicLog" v-on:close="editDialog=false" />
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-card flat class="my-3">

      <div class="d-flex justify-end px-5">
        <v-checkbox v-model="multiple" label="Multiple entries"></v-checkbox>
      </div>

      <v-row v-if="multiple">
        <v-col>
          <WriteMultipleDynamicLog :dynamicLog="dynamicLog" />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <slot name="top"></slot>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <v-form ref="usageForm" v-model="valid">
      <v-row>
        <v-col>
          <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
          <v-menu v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" :label="$store.getters.getTextMap().dates" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                v-on="on" :rules="objectRules"></v-text-field>
            </template>
            <v-date-picker v-model="form.date" range></v-date-picker>
          </v-menu>
        </v-col>
        <v-col align-self="center">
          <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
          <v-btn v-if="!loading" :color="$store.getters.getColorPalette().accentCode" outlined small @click="fetchLogs">
            {{$store.getters.getTextMap().view_logs}}


          </v-btn>

        </v-col>
      </v-row>
    </v-form>
    <v-form ref="filterForm" v-model="filterValid">
      <v-row>
        <v-col>
          <v-select v-if="!filterLoading" v-model="filterForm.param" :items="filterParamOptions" :label="$store.getters.getTextMap().param"
            :rules="objectRules" required item-text="label" item-value="value" clearable></v-select>
        </v-col>
        <v-col align-self="center">
          <v-select v-if="!filterLoading" v-model="filterForm.operator" :items="operators" item-text="label"
            item-value="value" :label="$store.getters.getTextMap().operator" :rules="objectRules" required
            :dark="$store.getters.getColorPalette().isDark" clearable
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"></v-select>
        </v-col>

        <v-col>
          <v-text-field :loading="filterLoading" v-model="filterForm.value" :counter="11" :rules="objectRules"
          :label="$store.getters.getTextMap().target_value" required></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-progress-circular indeterminate v-if="filterLoading" color="primary"></v-progress-circular>
          <v-btn v-if="!filterLoading" :color="$store.getters.getColorPalette().accentCode" text small
            @click="addFilter">
            {{$store.getters.getTextMap().add_filter}}

          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row v-if="filters.length>0">
      <v-col>
        <span><strong>{{$store.getters.getTextMap().filters}}
:</strong></span>
        <v-chip class="ma-2" close @click:close="deleteFilter(filter.key)" color="indigo" text-color="white"
          v-for="filter in filters" :key="filter.key">
          {{filter.param}} {{filter.operator}} {{filter.value}}
        </v-chip>
      </v-col>
    </v-row>

    <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>




    <v-row v-if="graphData.length>0">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$store.getters.getTextMap().search" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="tableHeaders" :items="filteredData" item-key="timestamp" :search="search"
            :single-expand="true" show-expand :expanded.sync="expanded" :loading="loading"
            loading-text="Loading... Please wait" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{$store.getters.getTextMap().dynamic_logs}}
 - {{dateRangeText}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn v-if="graphData.length>0" outlined :color="$store.getters.getColorPalette().downloadbtnColor"
                  small @click="downloadPDF">
                  {{$store.getters.getTextMap().download_table}}

                </v-btn>
                <a ref="csvDownload" hidden></a>
              </v-toolbar>
            </template>
            <template v-slot:item.from_timestamp="{ item }">
              <strong>{{ toReadableTime(item.from_timestamp) }}</strong>
            </template>
            <template v-slot:item.to_timestamp="{ item }">
              <strong>{{ toReadableTime(item.to_timestamp) }}</strong>
            </template>


            <!-- template for editing the dynamic logs details -->

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">

                <v-dialog v-model="editDynamicLogData" v-if="editDynamicLogData" hide-overlay misc>
                  <v-card :color="$store.getters.getColorPalette().backgroundColorCode"
                    :dark="$store.getters.getColorPalette().isDark" class="pt-5">

                    <v-card-text>
                      <div>
                        <EditWrittenDynamicLog :dynamicLog="dynamicLogProp" :selectedItem="selectedItem"
                          @close="closeDialog" @update="refreshLogs" />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>



                <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined
                  v-on:click="editDynamicLog(item)">{{$store.getters.getTextMap().edit_log}}

                  <v-icon right :isDark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-pencil</v-icon>
                </v-btn>


                <DeleteConfirmation v-on:confirm="deleteLogData(item)" title="Delete Confirmation"
                  description="Are you sure you want to delete this Log?">
                  <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>
                    {{$store.getters.getTextMap().delete_log}}

                    <v-icon right :isDark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                  </v-btn>
                </DeleteConfirmation>

              </td>
            </template>






          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
  import InfoAlert from '@/components/InfoAlert'
  import DeleteConfirmation from '@/components/DeleteConfirmation'
  import CreateDynamicLog from '@/components/crud_components/loggers/CreateDynamicLog'
  import DataProcessingUtillities from '@/utillities/DataProcessingUtillities'
  //import MultiAxisBarGraphByLabel from '@/components/graphs/MultiAxisBarGraphByLabel'
  // import MultiAxisMultiLineGraphByTimestamp from '@/components/graphs/MultiAxisMultiLineGraphByTimestamp'
  import moment from 'moment'
  import axios from 'axios'
  import WriteMultipleDynamicLog from '@/components/crud_components/loggers/WriteMultipleDynamicLog'
  import EditWrittenDynamicLog from '@/components/crud_components/loggers/EditWrittenDynamicLog';
  import jsPDF from 'jspdf'
  import 'jspdf-autotable'
  import { applyPlugin } from 'jspdf-autotable'
  applyPlugin(jsPDF)
  export default {
    name: 'DynamicLogger',
    props: ['dynamicLog'],
    components: {
      CreateDynamicLog,
      EditWrittenDynamicLog,
      InfoAlert,
      DeleteConfirmation,
      WriteMultipleDynamicLog
      //MultiAxisBarGraphByLabel,
      // MultiAxisMultiLineGraphByTimestamp
    },
    mounted() {
      this.init()
      this.form.date = []
      this.form.date[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
      this.form.date[1] = moment().subtract(1, 'd').format('YYYY-MM-DD')
      this.fetchLogs()
    },
    data() {
      return {
        sortBy: 'from_timestamp',
        sortDesc: false,
        multiple: false,
        isActive: false,
        editDialog: false,

        form: {
          date: null,
          log_id: null
        },
        groupBy: null,
        //date: new Date().toISOString().substr(0, 10),
        expanded: [],
        search: '',
        valid: false,
        groupValid: false,
        menu: false,
        meta: {},
        graphData: [],
        groupedData: [],
        showDismissibleAlert: false,
        loading: false,
        info: '',
        filters: [],
        filterLoading: false,
        filterValid: false,
        selectedItem: null,
        dialog: false,
        filterForm: {
          param: null,
          operator: '==',
          value: null
        },
        operators: [
          { label: 'Greater than', value: '>' },
          { label: 'Lesser than', value: '<' },
          { label: 'Equals to', value: '==' },
          { label: 'Greater than equal to', value: '>=' },
          { label: 'Lesser than equal to', value: '<=' },
        ],
        objectRules: [
          v => !!v || 'Required',
        ],
        headers: [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },

        ],
        groupByOptions: [
          { label: 'From Time', value: 'from_timestamp' },
          { label: 'To Time', value: 'to_timestamp' },
          { label: 'Primary Data', value: 'primary_field_data' },
        ],
        editDynamicLogData: false,
        dynamicLogProp: null,
        primaryFieldLabel:null,
        primaryFieldType:null
      }
    },
    computed: {
      dateRangeText() {
        if (this.form.date == null) {
          return null
        }
        return this.form.date.join(' - ')
      },

      fromTimeStamp() {
        if (this.form.date) {
          if (this.form.date.length > 1) {
            let dt = this.form.date[0].toString()
            if (Number(moment(dt, 'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X"))) {
              dt = this.form.date[1].toString()
            }
            return Number(moment(dt, 'YYYY-MM-DD').format("X"));
          } else {
            return Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X"));
          }
        } else {
          return null
        }
      },
      toTimeStamp() {
        if (this.form.date) {
          if (this.form.date.length > 1) {
            let dt = this.form.date[1].toString()
            if (Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X")) > Number(moment(dt, 'YYYY-MM-DD').format("X"))) {
              dt = this.form.date[0].toString()
            }
            return Number(moment(dt, 'YYYY-MM-DD').add(1, 'd').format("X"));
          } else {
            return Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').add(1, 'd').format("X"));
          }
        } else {
          return null
        }
      },

      filteredData() {
        let filteredData = this.graphData;
        filteredData = filteredData.map(obj => {
          if (Object.prototype.hasOwnProperty.call(obj, 'primary_field_data')) {
            obj[this.primaryFieldLabel] = obj['primary_field_data'];
            delete obj['primary_field_data'];
          }
          return obj;
        });

        for (let i of this.filters) {
          let filterFunc = () => true; 

          switch (i.operator) {
            case '>=': filterFunc = (data) => data[i.param] >= i.value; break;
            case '>': filterFunc = (data) => data[i.param] > i.value; break;
            case '==': filterFunc = (data) => data[i.param] == i.value; break;
            case '<=': filterFunc = (data) => data[i.param] <= i.value; break;
            case '<': filterFunc = (data) => data[i.param] < i.value; break;
          }
          filteredData = filteredData.filter(filterFunc);
        }
        return filteredData;
      },
      metaItems() {
        if (this.meta) {
          return Object.values(this.meta)
        }
        return []
      },
      filterParamOptions() {
        let op = []
        for (let i in this.meta) {
          op.push({
            label: i,
            value: i.label
          })
        }
       op.push({
          label:this.primaryFieldLabel,value:this.primaryFieldLabel
        })        
        return op
      },
      tableHeaders() {
        let headers = [
          {
            text: 'From',
            align: 'start',
            filterable: true,
            value: 'from_timestamp',
          },
          {
            text: 'To',
            // align: 'start',
            filterable: true,
            value: 'to_timestamp',
          },
          { text: this.dynamicLog.primary_field_label, value: this.primaryFieldLabel?this.primaryFieldLabel:"primary_field_data" },

        ]
        for (let i of this.metaItems) {
          // console.log(i)
          headers.push({ text: i.label, value: i.label })
        }
        return headers
      }

    },
    methods: {
      init() {
        if (this.dynamicLog) {
          this.meta = Object.assign({}, this.dynamicLog.meta)
          // console.log(this.meta)
          this.updateTableHeader()
        }
      },
      toReadableTimeFromEpoch(timestamp) {
        if (isNaN(timestamp)) {
          return null
        }
        return moment.unix(Number(timestamp))

      },
      refreshLogs() {
        this.fetchLogs();
      },
      closeDialog() {
        this.editDynamicLogData = !this.editDynamicLogData
      },
      toReadableTime(timestamp) {

        return moment(timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')

      },
      addFilter() {
        this.$refs.filterForm.validate()
        if (this.filterValid) {
          let d = Object.assign({}, this.filterForm)
          d.key = Number(moment().format("X"))
          this.filters.push(d)
          this.$refs.filterForm.reset()
        }
      },
      deleteFilter(key) {
        this.filters = this.filters.filter(
          function (data) { return data.key != key }
        );
      },
      deleteDynamicLog() {

        axios.post(this.$store.state.api + 'deleteDynamicLog', this.dynamicLog, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        }).then(response => {
          if (response.data.status === 'success') {
            this.info = 'Logger Deleted successfully';

            this.showDismissibleAlert = true;
            this.$store.dispatch('refreshDynamicLogs')
          } else {

            this.info = response.data.msg;

            this.showDismissibleAlert = true;

          }
          this.loading = false


        }).catch(err => {
          console.log(err)
          this.showDismissibleAlert = true
          this.info = err
          this.loading = false
        })

      },
      updateTableHeader() {
        this.headers = [
          {
            text: 'From',
            align: 'start',
            filterable: true,
            value: 'from_timestamp',
          },
          {
            text: 'To',
            // align: 'start',
            filterable: true,
            value: 'to_timestamp',
          },
          { text: this.dynamicLog.primary_field_label, value: 'primary_field_data' },

        ]
        for (let i of this.metaItems) {
          // console.log(i)
          this.headers.push({ text: i.label, value: i.label })
        }


      },
      editDynamicLog(item) {
        console.log('called');
        this.selectedItem = item;
        this.dynamicLogProp = { ...this.dynamicLog };
        this.editDynamicLogData = !this.editDynamicLogData
      },
      downloadPDF() {

        //let pdf = new jsPDF('landscape','pt',[512,600]);
        let pdf = new jsPDF();
        //pdf.table(20, 30, this.currentData);
        let header = []
        let keyMapping = {};
        console.log(this.headers);
        this.headers.forEach(val => {
          header.push(val.text);
          keyMapping[val.text] = val.value;
        })


        let body = []

        for (let i of this.filteredData) {
          const values = header.map(heading => {
            const key = keyMapping[heading];
            let value = i[key];

            if (key === "from_timestamp" || key === "to_timestamp") {
              value = this.toReadableTime(value);
            }
            const escaped = ('' + value)
            return `"${escaped}"`;
          })

          body.push(values)
        }


        pdf.autoTable({
          head: [header],
          body: body
        })
        pdf.save(this.dynamicLog.name + '_' + this.dateRangeText + '.pdf');

        let csvHeader = header.join(',') + "\n"
        let csv = csvHeader
        csv += body.map(e => e.join(",")).join("\n");
        let csvData = new Blob([csv], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);
        //let hiddenElement = document.createElement('a');
        let hiddenElement = this.$refs.csvDownload;
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = this.dynamicLog.name + '_' + this.dateRangeText + '.csv';
        hiddenElement.click();

      },
      groupData() {
        this.$refs.groupForm.validate()
        if (this.groupValid) {
          this.groupedData = []
          let g = DataProcessingUtillities.groupBy(this.filteredData, this.groupBy)
          for (let i in g) {

            for (let j of g[i]) {
              j['label'] = i
            }


            this.groupedData.push(g[i])
          }
          //this.groupedData=g
          //console.log(g)
        }
      },
      deleteLogData(logData) {
        this.loading = false
        let payload = Object.assign({ log_id: this.dynamicLog.log_id }, logData)
        payload['to_timestamp'] = moment(payload['to_timestamp']).unix()
        payload['from_timestamp'] = moment(payload['from_timestamp']).unix()
        axios.post(this.$store.state.api + 'deleteDynamicLogData', payload, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        }).then(response => {
          if (response.data.status === 'success') {
            this.info = 'Entry Deleted successfully';

            this.showDismissibleAlert = true;
            this.fetchLogs()
          } else {

            this.info = response.data.msg;

            this.showDismissibleAlert = true;

          }
          this.loading = false


        }).catch(err => {
          console.log(err)
          this.showDismissibleAlert = true
          this.info = err
          this.loading = false
        })
      },
      fetchLogs() {
        let logs = this.$store.state.dynamicLogs
        console.log(logs,"test");
       let [log] =  logs.filter((log)=> log.log_id==this.dynamicLog.log_id)
       this.primaryFieldLabel =log.primary_field_label
       this.primaryFieldType=log.primary_field_type
        this.$refs.usageForm.validate()
        if (this.valid) {
          this.loading = true
          //let fromTimeStamp=this.fromTimeStamp
          //let toTimeStamp=this.toTimeStamp
          let payload = {
            log_id: this.dynamicLog.log_id,
            from_timestamp: this.fromTimeStamp,
            to_timestamp: this.toTimeStamp
          }
          let endpoint = 'getDynamicLogDataBetweenTimestamp'
          //console.log(fromTimeStamp)

          axios.post(this.$store.state.api + endpoint, payload, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          })
            .then(response => {
              //console.log("report data received")
              //console.log(response.data)
              if (response.data.status === 'success') {
                this.updateTableHeader()
                let data = []

                for (let i of response.data.data) {

                  //   i['timestamp']=moment(i['timestamp']).unix()
                  let temp = {
                    from_timestamp: i.from_timestamp,
                    to_timestamp: i.to_timestamp,
                    primary_field_data: i.primary_field_data,
                    ...i.meta_data
                  }

                  data.push(temp)

                }
                this.graphData = data
                //console.log(response.data.data)
                //this.report['parameter']=this.form.parameter
                //this.report['date']=this.form.date
                if (this.graphData.length == 0) {
                  this.info = "No data to show";
                  this.showDismissibleAlert = true;
                }

              } else {
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading = false
              }
              this.loading = false

            })
            .catch(err => {
              console.log(err)
              this.showDismissibleAlert = true
              this.info = err
              this.loading = false
            })

        }
      },
    },

  }
</script>