<template>
    <div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
        <div class="py-0">
          
          <v-form
    ref="logForm"
    v-model="valid" >
      <v-card flat :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title
         :color="$store.getters.getColorPalette().accentCode"> 
          {{mode}}{{$store.getters.getTextMap().production_logger}}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col>
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.name"
                    :counter="30"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().name"
                    required
                    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.input_type"
                            :items="inputTypes"
                            :label="$store.getters.getTextMap().input_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col v-if="form.input_type && form.input_type !='text'">
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.input_meta"
                            :items="inputMetas"
                            :label="$store.getters.getTextMap().input + form.input_type "
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.input"
                    :counter="18"
                    :rules="columnRules"
                    :label="$store.getters.getTextMap().input_name"
                    required
                    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.output_type"
                            :items="inputTypes"
                            :label="$store.getters.getTextMap().output_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col v-if="form.output_type && form.output_type !='text'">
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.output_meta"
                            :items="outputMetas"
                            :label="$store.getters.getTextMap().input+ form.output_type "
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.output"
                    :counter="18"
                    :rules="columnRules"
                    :label="$store.getters.getTextMap().output_name"
                    required
                    ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.output_param_name"
                    :counter="18"
                    :rules="columnRules"
                    :label="$store.getters.getTextMap().param_name"
                    required
                    ></v-text-field>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="workspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="output_param_type"
                            :items="param_types"
                            :label="$store.getters.getTextMap().param_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="dev"
                            :items="deviceOptions"
                            :label="$store.getters.getTextMap().device"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.output_params"
                            :items="paramOptions"
                            :label="$store.getters.getTextMap().output_params"
                            dense
                            filled
                            multiple
                            item-text="label"
                            item-value="value"
                            required
                            small-chips
                            :rules="listRules"
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                      <v-checkbox  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.calculate_production"
      :label="$store.getters.getTextMap().compute_production"
    ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.calculate_energy"
      :label="$store.getters.getTextMap().compute_energy"
    ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="form.calculate_energy">
                  <v-col >
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="energy_param_type"
                            :items="energy_param_types"
                            :label="$store.getters.getTextMap().energy_param_type"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                  <v-col v-if="energy_param_type!='combo'">
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="energyWorkspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                  </v-col>
                  
                    <v-col v-if="energy_param_type!='combo'">
                        <v-select :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="energyDev"
                            :items="energyDeviceOptions"
                            :label="$store.getters.getTextMap().energy_device"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.energy_param"
                            :items="energyParamOptions"
                            :label="$store.getters.getTextMap().energy_param"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="form.calculate_production">
                  <v-col>
                    <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.production_operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().production_operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
                    :loading="loading"
                    v-model="form.production_correction_factor"
                    :counter="12"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().production_correction_factor"
                    required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.production_factor"
                            :items="production_factors"
                            :label="$store.getters.getTextMap().production_factor"
                            dense
                            filled                            
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                  </v-col>
                  
                </v-row>
                <v-row>
                  <v-col v-if="prodFactorInclusions('output')">
                    <v-select :dark="$store.getters.getColorPalette().isDark" 
         :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.output_operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().output_operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                  </v-col>
                  <v-col v-if="prodFactorInclusions('output')">
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    :loading="loading"
                    v-model="form.output_correction_factor"
                    :counter="12"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().output_correction_factor"
                    required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="prodFactorInclusions('input')">
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.input_operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().input_operator"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                  </v-col>
                  <v-col v-if="prodFactorInclusions('input')">
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    :loading="loading"
                    v-model="form.input_correction_factor"
                    :counter="12"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().input_correction_factor"
                    required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="form.calculate_production" >
                  <v-col class="text-h5">
                    <span    :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().production }} = </span>
                    <span   :color="$store.getters.getColorPalette().accentCode">({{form.param_name?form.param_name:'param'}} {{form.production_operator}} {{form.production_correction_factor}})</span>
                    <span   :color="$store.getters.getColorPalette().accentCode" v-if="prodFactorInclusions('output')"> * ({{form.output?form.output:'output'}} {{form.output_operator}} {{form.output_correction_factor}})</span>
                    <span   :color="$store.getters.getColorPalette().accentCode" v-if="prodFactorInclusions('input')"> * ({{form.input?form.input:'input'}} {{form.input_operator}} {{form.input_correction_factor}})</span>
                  </v-col>
                  <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading && form.calculate_energy"
                    v-model="form.energy_unit"
                    :counter="6"
                    
                    :label="$store.getters.getTextMap().energy_unit"
                    
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.prod_unit"
                    :counter="6"
                    
                    :label="$store.getters.getTextMap().production_unit"
                    
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{mode}}
          </v-btn>
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
            {{ $store.getters.getTextMap().cancel  }}
          </v-btn>
        </v-card-actions>
      </v-card>
          </v-form>
        </div>
    </div>

</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateProductionLog',
    props:['productionLog'],
    components:{
        InfoAlert
    },
    mounted(){
      if(this.productionLog){
        this.initFlag=true
        this.energyInitFlag=true
        let d=Object.assign({},this.productionLog)
        this.output_param_type=d.output_param_type
        this.energy_param_type=d.energy_param_type
        this.form=d
        this.mode='edit'
        
      }
    },
    data() {
        return {
          initFlag:false,
          energyInitFlag:false,
            showDismissibleAlert:false,
            info:'',
            loading:false,
            workspace:null,
            dev:null,
            energyWorkspace:null,
            energyDev:null,
            valid:false,
            output_param_type:null,
            energy_param_type:null,
            mode:'create',
            form:{
                name:null,
                input:null,
                output_type:null,
                output_meta:null,
                output:null,
                output_param_name:null,
                output_params:[],
                output_param_type:'parameter',
                calculate_production:false,
                production_factor:'none',
                production_correction_factor:null,
                production_operator:null,
                output_correction_factor:null,
                output_operator:null,
                calculate_energy:false,
                
            },
            param_types:[
                {label:'Parameter',value:'parameter'},
                {label:'Computed Param',value:'computed'},
            ],
            energy_param_types:[
                {label:'Parameter',value:'parameter'},
                {label:'Computed Param',value:'computed'},
                {label:'Combo Param',value:'combo'},
            ],
            inputTypes:[
              {label:'List',value:'list'},
              {label:'Text',value:'text'},
            ],
            operators:[
                {label:'*',value:'*'},
                {label:'^',value:'^'},
                {label:'+', value:'+'},
                {label:'-', value:'-'},
            ],
            production_factors:[
                {label:'None',value:'none'},
                {label:'Output',value:'output'},
                {label:'Input', value:'input'},
                {label:'Input & Output', value:'inputoutput'},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
            ],
            columnRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 18 && v.length > 0) || 'Name must be less than 18 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => !isNaN(v) || 'It must be a Number',
                v => ( v && v.toString().length <= 12 && v.toString().length > 0) || 'Number must be less than 13 characters',
            ],
            unitRules:[
                v => !!v || 'Unit is required',
                v => /\S+/.test(v) || 'Unit is required',
                v => (v && v.length <= 8) || 'Unit length is invalid'
            ],
            outputMetaRules:[
                v => (!!v ||  this.form.output_type=='text') || 'Required',
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            listRules:[
                v=> !!v || 'Required',
                v => (v && v.length>0) || 'You have to select something',
            ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions(){
            let op=[]
          
          if(this.workspace && this.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: "Unassigned Name"})
                  }
                  
              
          }
          }
          return op
        },
        energyDeviceOptions(){
            let op=[]
          
          if(this.energyWorkspace && this.energyWorkspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.energyWorkspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: "Unassigned Name"})
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.dev && this.dev.device_id ){
              let g=[]
              if(this.output_param_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.dev.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: "Unassigned Name" })
                  }
          }
              }else if(this.output_param_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.dev.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }
              
          }
          return op
        },
        energyParamOptions(){
             
            let op=[]
          
          if(this.energyDev && this.energyDev.device_id ){
              let g=[]
              if(this.energy_param_type=='parameter'){
              g=this.$store.getters.getParametersByDeviceID(this.energyDev.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: "Unassigned Name" })
                  }
          }
              }else if(this.energy_param_type=='computed'){
                  g=this.$store.getters.getComputedParamsByDeviceID(this.energyDev.device_id)
                  for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
          }
              }
          }
          if(this.energy_param_type=='combo'){
                let g=this.$store.state.comboParams
                for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: "Unassigned Name" })
                  }
              }
              
          }
          return op
          
        },
        outputMetas(){
          let op=[]
          if(this.form.output_type && this.form.output_type=='list'){
            let lsts=this.$store.state.lists
            for(let i of lsts){
              op.push({
                label:i.name,
                value:i.list_id
              })
            }
          }
          return op
        },
        inputMetas(){
          let op=[]
          if(this.form.input_type && this.form.input_type=='list'){
            let lsts=this.$store.state.lists
            for(let i of lsts){
              op.push({
                label:i.name,
                value:i.list_id
              })
            }
          }
          return op
        }

    },
    methods: {
      prodFactorInclusions(t){
        let pattern = /^(output)$|^(input)$/;
        if (pattern.test(t)){
          let pat=new RegExp(t)
          return pat.test(this.form.production_factor)
        }else{
          return false
        }
      },
        onSubmit(){
            this.$refs.logForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.output_param_type=this.output_param_type
            this.form.energy_param_type=this.energy_param_type
            let d=Object.assign({}, this.form)
            let api='createProductionLog'
            if(this.mode && this.mode=='edit'){
              api='updateProductionLog'
            }
            axios.post(this.$store.state.api+api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          this.$store.dispatch('refreshProductionLogs')
          this.loading=false
          this.onReset()
      }else{
          this.info = response.data.msg;
  
          this.showDismissibleAlert = true;
      
      this.loading=false
      }}).catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
      this.loading=false
      })
            }
        },
        onReset(){
            //this.$refs.logForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
        
    },
    watch:{
        output_param_type:  function (){
         if(this.initFlag){
           this.initFlag=false
         }else{
           this.form.output_params=[]
         }
         },
        energy_param_type:  function (){
         if(this.energyInitFlag){
           this.energyInitFlag=false
         }else{
           this.form.energy_param=null
         }
         },
    }
}
</script>
<style scoped>


</style>