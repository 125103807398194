<template>
<v-lazy
        v-model="isActive"
        :options="{
          threshold: .5
        }"
        
        transition="fade-transition"
      >
    <div :color="$store.getters.getColorPalette().accentCode">
        <v-card 
        flat
         @click="dialog = true" class="px-2 py-0 mx-1 my-2" 
        
        :color="$store.getters.getColorPalette().loggerColor"
      >
        
        <v-card-title  :style="{color:$store.getters.getColorPalette().accentCode}" >
          {{productionLog.name}} <v-spacer></v-spacer><v-icon color="orange lighten-1"> mdi-clipboard-list-outline</v-icon>
        </v-card-title>
        </v-card>
        <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
    <v-toolbar
        :dark="$store.getters.getColorPalette().isDark"
         :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
           :dark="$store.getters.getColorPalette().isDark"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{productionLog.name }}    {{$store.getters.getTextMap().logs}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
       <ProductionLogDetails :productionLog="productionLog">
        <template v-slot:top >
          <WriteProductionLog :productionLog="productionLog" />
        </template>
      </ProductionLogDetails>
    </v-card>
        </v-dialog>
    </div>
</v-lazy>
</template>
<script>

import ProductionLogDetails from '@/components/panels/ProductionLogDetails'
import WriteProductionLog from '@/components/crud_components/WriteProductionLog'

export default {
    name:'ProductionLogger',
    props:['productionLog'],
    components:{
        ProductionLogDetails,
        WriteProductionLog
    },
    mounted() {
    
    },
    data(){
        return {
            isActive:false,
            dialog:false,
            
        }
    },
    computed:{
        
    },
    methods: {
        deleteProductionLog(){

        }, 
    },
}
</script>


