<template>
    <v-container class="" fluid>
        <span v-if="$store.state.productionLogs && $store.state.productionLogs.length>0">
            <v-subheader :style="{ color: $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().production_logs}}</v-subheader><v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-row>
        <v-col  cols="4" v-bind:key="productionLog.log_id" v-for="productionLog in $store.state.productionLogs">
            <ProductionLogger  :productionLog="productionLog"/>
        </v-col>
        </v-row>
        </span>
    </v-container>
</template>

<script>
import ProductionLogger from '@/components/items/ProductionLogger'
export default {
    name:'ProductionLoggerList',
    data(){
        return {

            showDismissibleAlert:false,
        info:'You do not have access for deleting a node from workspace'
        }
    },
    components:{
        ProductionLogger
    },
    computed:{
   
    },
    methods:{
        // deleteNode(node){
        //     //console.log(this.$store.state.user.user_level)
        //    /*if(this.$store.state.user.user_level>10){
        //        this.$store.dispatch('deleteNode',node)
        //    }else{
        //        this.showDismissibleAlert=true
        //    }*/
        //    this.$emit('deleteNode',node)
           
        // }
    }
}
</script>

<style scoped>

</style>