import DataFrame from 'dataframe-js';

//var DataFrame = require('dataframe-js').DataFrame;

export default {
    name:'DataProcessingUtillities',

    unpack(rows,key){
        return rows.map((row)=>row[key])
    },

    mergeDataByTimeStamp(data1,data2,dataKey1,dataKey2){
        let arr1=[]
        for(let i of data1){
            arr1.push({
                'timestamp':i['timestamp'],
                'data1':i[dataKey1]
            })
        }
        let arr2=[]
        for(let i of data2){
            arr2.push({
                'timestamp':i['timestamp'],
                'data2':i[dataKey2]
            })
        }

        let df = new DataFrame(arr1, ['timestamp', 'data1']);
        let df2 = new DataFrame(arr2, ['timestamp', 'data2']);

        df=df.fullJoin(df2,'timestamp')
        return df.sortBy('timestamp')
    },
    joinDataByTimeStamp(data1,data2,dataKey1,dataKey2,join='left'){
        let arr1=[]
        for(let i of data1){
            arr1.push({
                'timestamp':i['timestamp'],
                'data1':i[dataKey1]
            })
        }
        let arr2=[]
        for(let i of data2){
            arr2.push({
                'timestamp':i['timestamp'],
                'data2':i[dataKey2]
            })
        }

        let df = new DataFrame(arr1, ['timestamp', 'data1']);
        let df2 = new DataFrame(arr2, ['timestamp', 'data2']);

        df=df.join(df2,'timestamp',join)
        return df.sortBy('timestamp')
    },

    mergeDataFrameAndDataByKey(dataFrame1,key,data,dataKey='data',zero=true){
        let arr=[]
        for(let i of data){
            let temp={
                'data1':i[dataKey]
            }
            temp[key]=i[key]
            //console.log(temp)
            arr.push(temp)
        }
        if(!dataFrame1){
            dataFrame1=new DataFrame([],[key,dataKey])
        }
        let df = new DataFrame(arr, [key, 'data1']);
        //df=df.fillMissingValues(0, ['data1'])
        dataFrame1=dataFrame1.fullJoin(df,key);
        if(zero){
            dataFrame1=dataFrame1.fillMissingValues(0, [dataKey,'data1'])
        }
        dataFrame1=dataFrame1.map(row=>row.set(dataKey,row.get('data1')+row.get(dataKey)))
        dataFrame1=dataFrame1.drop('data1')
        return dataFrame1.sortBy(key)

    },
    normaliseSeries(t){
        let v=undefined
        let p=[]
        for(let i of t){
            if(i!=undefined){
                v=i
            }
            p.push(v)
        }
        let q=[]
        for(let i=(p.length-1);i>=0;i--){
            if(p[i]!=undefined){
                v=p[i]
            }
            q.push(v)
            //console.log(i)
        }
        return q.reverse()
    },
    seriesSum(s){
        let v=0
        if(s==null){
            return v
        }
        for(let i of s){
            if(i!=undefined){
                v+=i
            }
        }
        return v
    },
    groupBy(arr, key){
        let g={}
        for (let i of arr){
            if(!(i[key] in g)){
                g[i[key]]=[]
            }
            g[i[key]].push(i)
        }
        return g
    },
    sumOfKey(arr,key){
        return arr.reduce((accumulator, currentValue)=>{
            if(currentValue[key] && !isNaN(currentValue[key])){
                return accumulator+Number(currentValue[key])
            }
            return accumulator
        },0)
    },
    dec2bin(dec){
        return (dec >>> 0).toString(2);
      },
    bin2dec(bin){
        return parseInt(bin, 2).toString(10);
      }

}