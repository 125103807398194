<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}" >
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col></v-col>
            <v-spacer></v-spacer>
            <v-col align="right" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
                <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
    <v-icon
    small
    class="mr-2"
    @click="editDialog=!editDialog"
    v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
  >
    mdi-pencil
  </v-icon>
  <DeleteConfirmation v-on:confirm="deleteCapacityLog()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">                                                                         
  <v-icon
    small
    
  >
    mdi-delete
  </v-icon>
  </DeleteConfirmation>

</span>
            </v-col>
        </v-row>
        <v-row v-if="editDialog">
          <v-col cols="12"><CreateCapacityLog :capacityLog="capacityLog" v-on:close="editDialog=false"/>
          </v-col>
        </v-row>
        
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            
            <slot name="top"></slot>
        </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-form
ref="usageForm"
v-model="valid"

>
    <v-row>
        <v-col>
            <v-skeleton-loader
    v-if="loading"
  class="mx-auto"
  type="text"
></v-skeleton-loader>
            <v-menu
            v-if="!loading"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="$store.getters.getTextMap().dates"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="objectRules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="form.date"
      range
      
    ></v-date-picker>
  </v-menu>
        </v-col>
        <v-col align-self="center">
            <v-skeleton-loader
    v-if="loading"
  class="mx-auto"
  type="text"
></v-skeleton-loader>
            <v-btn
      v-if="!loading"
      :color="$store.getters.getColorPalette().accentCode"
        outlined
        small
        @click="fetchLogs"
      >
      {{$store.getters.getTextMap().view_logs}}

      </v-btn>
      
        </v-col>
    </v-row>
    </v-form>
        <v-form
ref="filterForm"
v-model="filterValid"

>
<v-row>
<v-col>
  <v-select
v-if="!filterLoading"
      v-model="filterForm.param"
      :items="filterParamOptions"       
      :label="$store.getters.getTextMap().param"
     :rules="objectRules"
     required
      
      clearable
    ></v-select>
</v-col>
<v-col align-self="center">
      <v-select
    v-if="!filterLoading"
          v-model="filterForm.operator"
          :items="operators"
          item-text="label"
          item-value="value"          
          :label="$store.getters.getTextMap().operator"
         :rules="objectRules"
         required
       :dark="$store.getters.getColorPalette().isDark"
          clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
    </v-col>

<v-col>
  <v-text-field
                    :loading="filterLoading"
                    v-model="filterForm.value"
                    :counter="11"
                    :rules="objectRules"
                    :label="$store.getters.getTextMap().target_value"
                    required
                ></v-text-field>
</v-col>
<v-col align-self="center">
  <v-progress-circular
  indeterminate
  v-if="filterLoading"
  color="primary"
></v-progress-circular>
  <v-btn
      v-if="!filterLoading"
        :color="$store.getters.getColorPalette().accentCode"
        text small
        @click="addFilter"
      >
      {{$store.getters.getTextMap().add_filter}}

      </v-btn>
</v-col>
</v-row>
</v-form>

<v-row v-if="filters.length>0">
  <v-col>
    <span><strong>Filters:</strong></span>
    <v-chip
  class="ma-2"
  close
  @click:close="deleteFilter(filter.key)" 
  color="indigo"
  text-color="white" v-for="filter in filters"
    :key="filter.key">
    {{filter.param}} {{filter.operator}} {{filter.value}}
    </v-chip>
  </v-col>
</v-row>
<v-progress-circular
  indeterminate
  v-if="loading"
  color="primary"
></v-progress-circular>


<v-row v-if="filteredData && filteredData.length>0">
  <v-col cols="12">
    <MultiAxisMultiLineGraphByTimestamp :timeUnit="'day'"  :label="capacityLog.name +' Trend'" :graphData="filteredData" :dataKeys="trendDataKeys" />
  </v-col>
</v-row>



<v-row v-if="graphData.length>0">
        <v-col cols="12">
            <v-card>
<v-card-title>
  <v-text-field
    v-model="search"
    append-icon="mdi-magnify"
    label="Search"
    single-line
    hide-details
  ></v-text-field>
</v-card-title>
<v-data-table
  :headers="headers"
  :items="filteredData"
  item-key="timestamp"
  :search="search"
  :single-expand="true"
  show-expand
  :expanded.sync="expanded"
  :loading="loading"
loading-text="Loading... Please wait"
>
<template v-slot:top>
  <v-toolbar
    flat
  >
    <v-toolbar-title>Capacity Logs - {{dateRangeText}}</v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    ></v-divider>
    <v-spacer></v-spacer>
    <v-btn 
            v-if="graphData.length>0"
            outlined 
           :color="$store.getters.getColorPalette().downloadbtnColor"                                     
            small
            @click="downloadPDF">
            Download Table
            </v-btn>
    <a ref="csvDownload" hidden></a>
  </v-toolbar>
      </template>
      <template v-slot:item.timestamp="{ item }">
              <strong>{{ toReadableTime(item.timestamp) }}</strong>
    </template>
      <template v-slot:expanded-item="{ headers, item }">
  <td :colspan="headers.length">
    <DeleteConfirmation v-on:confirm="deleteLogData(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">                                                                         
    <v-icon small color="red">mdi-delete</v-icon>
    </DeleteConfirmation>
   
  </td>
</template>
      
      
</v-data-table>
</v-card>
        </v-col>
    </v-row>
    </v-container>

</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import CreateCapacityLog from '@/components/crud_components/loggers/CreateCapacityLog'
import DataProcessingUtillities from '@/utillities/DataProcessingUtillities'
//import MultiAxisBarGraphByLabel from '@/components/graphs/MultiAxisBarGraphByLabel'
import MultiAxisMultiLineGraphByTimestamp from '@/components/graphs/MultiAxisMultiLineGraphByTimestamp'
import moment from 'moment'
import axios from 'axios'
// import WriteCapacityLog from '@/components/crud_components/loggers/WriteCapacityLog'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
export default {
name:'CapacityLogger',
props:['capacityLog'],
components:{
    CreateCapacityLog,
    // WriteCapacityLog,
    InfoAlert,
    DeleteConfirmation,
    //MultiAxisBarGraphByLabel,
    MultiAxisMultiLineGraphByTimestamp
},
mounted() {
    this.form.date=[]
    this.form.date[0]=moment().subtract(18,'d').format('YYYY-MM-DD')
    this.form.date[1]=moment().subtract(1,'d').format('YYYY-MM-DD')
    this.fetchLogs()
},
data(){
    return {
         showDialog:false,
        isActive:false,
        editDialog:false,
        
        dialog:false,
        form:{
            date:null,
            log_id:null
        },
        groupBy:null,
        //date: new Date().toISOString().substr(0, 10),
        expanded: [],
        search:'',
        valid:false,
        groupValid:false,
        menu:false,
        graphData:[],
        groupedData:[],
        showDismissibleAlert:false,
        loading:false,
        info:'',
        filters:[],
        filterLoading:false,
        filterValid:false,
        filterForm:{
          param:null,
          operator:'==',
          value:null
        },
        operators:[
                {label:'Greater than', value:'>'},
                {label:'Lesser than', value:'<'},
                {label:'Equals to', value:'=='},
                {label:'Greater than equal to', value:'>='},
                {label:'Lesser than equal to', value:'<='},
            ],
        filterParamOptions:[
          { text: 'Date', value: 'date' },
          { text: 'People', value: 'people' },
      { text: 'Co2 Level', value: 'co2_level' },
      { text: 'Temperature', value: 'temperature' },
      { text: 'Remarks', value: 'remarks' },
        ],
         objectRules:[
            v => !!v || 'Required',
        ],
        headers: [
      {
        text: 'Date',
        align: 'start',
        filterable: false,
        value: 'date',
      },
     // {text:'timestamp',value:'timestamp'},
      { text: 'From (HRS)', value: 'fromtime' },
      { text: 'To (HRS)', value: 'totime' },
      { text: 'Input', value: 'input' },
      { text: 'Output', value: 'output' },
      { text: 'Machine', value: 'machine' },
      { text: 'Remarks', value: 'remarks' },
    ],
    groupByOptions:[
      {label:'People',value:'people'},
      {label:'Co2 Level',value:'co2_level'},
      {label:'temperature',value:'temperature'},
    ]
    }
},
computed:{
    dateRangeText () {
      if(this.form.date==null){
        return null
      }
    return this.form.date.join(' - ')
  },
  
  fromTimeStamp(){
    if(this.form.date){
      if(this.form.date.length>1 ){
        let dt=this.form.date[0].toString()
        if(Number(moment(dt,'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"))){
          dt=this.form.date[1].toString()
        }
        return Number(moment(dt,'YYYY-MM-DD').format("X"));
      }else{
        return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"));
      }
    }else{
      return null
    }
  },
  toTimeStamp(){
    if(this.form.date){
      if(this.form.date.length>1 ){
        let dt=this.form.date[1].toString()
        if(Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X")) > Number(moment(dt,'YYYY-MM-DD').format("X"))){
          dt=this.form.date[0].toString()
        }
        return Number(moment(dt,'YYYY-MM-DD').add(1,'d').format("X"));
      }else{
        return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').add(1,'d').format("X"));
      }
    }else{
      return null
    }
  },
  
  trendDataKeys(){
    let dataKeys=['people','co2_level']
    if(this.capacityLog.set_temperature){
      
      dataKeys.push('set_temperature')

    }
    if(this.capacityLog.aqi){
      dataKeys.push('aqi')

    }
    console.log(dataKeys)
    return dataKeys
  },
  filteredData(){
    let filteredData=this.graphData
    for(let i of this.filters){
      let filterFunc=()=>{
      }
      switch (i.operator) {
           case '>=': filterFunc=(data)=>{return data[i.param]>=i.value};
           break;
           case '>': filterFunc=(data)=>{return data[i.param]>i.value};
           break;
           case '==': filterFunc=(data)=>{return data[i.param]==i.value};
           break;
           case '<=': filterFunc=(data)=>{return data[i.param]<=i.value};
           break;
           case '<': filterFunc=(data)=>{return data[i.param]<=i.value};
           break;
      }
      filteredData=filteredData.filter(filterFunc);
    }
    return filteredData
  },

},
methods: {
    toReadableTime(timestamp){
        if(isNaN(timestamp)){
            return null
        }
        return moment.unix(Number(timestamp))

    },
  addFilter(){
    this.$refs.filterForm.validate()
        if(this.filterValid){
          let d=Object.assign({}, this.filterForm)
          d.key=Number(moment().format("X"))
          this.filters.push(d)
          this.$refs.filterForm.reset()
        }
  },
  deleteFilter(key){
    this.filters=this.filters.filter(
  function(data){ return data.key != key }
);
  },
    deleteCapacityLog(){

      axios.post(this.$store.state.api+'deleteCapacityLog',this.capacityLog,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
      }}).then(response=>{
        if(response.data.status==='success'){
          this.info = 'Logger Deleted successfully';
          
          this.showDismissibleAlert = true;
          this.$store.dispatch('refreshCapacityLogs')
        }else{
         
          this.info = response.data.msg;
          
          this.showDismissibleAlert = true;
          
        }
  this.loading=false

        
      }).catch(err=>{
  console.log(err)
this.showDismissibleAlert=true
        this.info=err
        this.loading=false
        })

    },
    updateTableHeader(){
        this.headers= [
      {
        text: 'Time',
        align: 'start',
        filterable: false,
        value: 'timestamp',
      },
      { text: 'people', value: 'people' },
      { text: 'temperature', value: 'temperature' },
      { text: 'set Temperature', value: 'set_temperature' },
      { text: 'CO2 Level', value: 'co2_level' },
      { text: 'AQI', value: 'aqi' },
      { text: 'Remarks', value: 'remarks' },
      
    ]
    
      //this.headers.push({ text: this.productionLabel, value: 'production' })


    },
    downloadPDF(){

      //let pdf = new jsPDF('landscape','pt',[512,600]);
        let pdf = new jsPDF();
        //pdf.table(20, 30, this.currentData);
        let header=['Date','People','Temperature',"Set Temperature", 'CO2 Level', 'AQI',"Remarks"]
        
        let body=[]
        for (let i of this.graphData){
            let temp=[]
            temp.push(this.toReadableTime(i['timestamp']))
            temp.push(i.people)
            temp.push(i.temperature)
            temp.push(i.set_temperature)
            temp.push(i.co2_level)
            temp.push(i.aqi)
            temp.push(i.remarks)

            body.push(temp)
        }
        pdf.autoTable({
            head: [header],
            body: body
            })
        pdf.save(this.capacityLog.name+'_'+this.dateRangeText+'.pdf');

        let csvHeader=header.join(',')+"\n"
        let csv=csvHeader
        csv+=body.map(e => e.join(",")).join("\n");
        let csvData = new Blob([csv], { type: 'text/csv' });  
        let csvUrl = URL.createObjectURL(csvData);
        //let hiddenElement = document.createElement('a');
        let hiddenElement = this.$refs.csvDownload;
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = this.capacityLog.name+'_'+this.dateRangeText + '.csv';
        hiddenElement.click();

  },
  groupData(){
    this.$refs.groupForm.validate()
    if(this.groupValid){
      this.groupedData=[]
      let g=DataProcessingUtillities.groupBy(this.filteredData,this.groupBy)
      for(let i in g){
        
        for(let j of g[i]){
          j['label']=i
        }
        
        
        this.groupedData.push(g[i])
      }
      //this.groupedData=g
      //console.log(g)
    }
    },
    deleteLogData(logData){
      this.loading=false
      axios.post(this.$store.state.api+'deleteCapacityLogData',logData,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
      }}).then(response=>{
        if(response.data.status==='success'){
          this.info = 'Entry Deleted successfully';
          
          this.showDismissibleAlert = true;
          this.fetchLogs()
        }else{
         
          this.info = response.data.msg;
          
          this.showDismissibleAlert = true;
          
        }
  this.loading=false

        
      }).catch(err=>{
  console.log(err)
this.showDismissibleAlert=true
        this.info=err
        this.loading=false
        })
    },
      fetchLogs(){
        this.$refs.usageForm.validate()
        if(this.valid){
          this.loading=true
          //let fromTimeStamp=this.fromTimeStamp
          //let toTimeStamp=this.toTimeStamp
          let payload={
              log_id:this.capacityLog.log_id,
  from_timestamp:this.fromTimeStamp,
  to_timestamp:this.toTimeStamp
}
let endpoint='getCapacityLogDataBetweenTimestamp'
//console.log(fromTimeStamp)

axios.post(this.$store.state.api+endpoint,payload,{headers: {
  Authorization: 'Bearer '+ this.$store.state.jwt
}})
.then(response=>{
  //console.log("report data received")
  //console.log(response.data)
  if(response.data.status==='success'){
      this.updateTableHeader()
        let data=[]
        ///this.search=response.data.data
        for (let i of response.data.data){
          //this.info=response.data.data
          i['timestamp']=moment(i['timestamp']).unix()
            data.push(i)
        
        }
        this.graphData=data
            //console.log(response.data.data)
            //this.report['parameter']=this.form.parameter
            //this.report['date']=this.form.date
            if(this.graphData.length==0){
                this.info = "No data to show";
                    this.showDismissibleAlert = true;
            }

}else{
  this.info = response.data.msg;
  //this.info = d;
        this.showDismissibleAlert = true;
  //console.log(response.data.msg)
  this.loading=false
}
  this.loading=false

})
.catch(err=>{
  console.log(err)
this.showDismissibleAlert=true
        this.info=err
        this.loading=false
        })

    }
},
    cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }  
},
}
</script>