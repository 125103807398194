<template>
    <v-form
    ref="groupForm"
    v-model="valid"
    
  >
  <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card>
        <v-card-title class="headline">
          {{$store.getters.getTextMap().write_in_logbook}}
        </v-card-title>
        <v-card-text>
<v-container>
            <v-row>
          <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.date"
            :label="$store.getters.getTextMap().date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
            </v-col>


<v-col>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-menu
        v-if="!loading"
        ref="menufrom"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.fromTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.fromTime"
            :label="$store.getters.getTextMap().from_time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu3"
          v-model="form.fromTime"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(form.fromTime)"
        ></v-time-picker>
      </v-menu>
            </v-col>

            <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            :label="$store.getters.getTextMap().to_date"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.to_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
            </v-col>

            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.toTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.toTime"
            :label="$store.getters.getTextMap().to_time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="form.toTime"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(form.toTime)"
        ></v-time-picker>
      </v-menu>
            </v-col>

            </v-row>
            <v-row>
                <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
        v-if="!loading && productionLog.input_type=='list'"
        v-model="form.input"
        :items="inputOptions"
        :label="inputLabel"
        dense
        filled
        item-text="label"
        item-value="value"
        required
        :rules="objectRules"
    ></v-select>
            <v-text-field
            v-else
    :loading="loading"
      v-model="form.input"
      :counter="8"
      :rules="floatRules"
      :label="inputLabel"
      required
    ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
        v-if="!loading && productionLog.output_type=='list'"
        v-model="form.output"
        :items="outputOptions"
        :label="outputLabel"
        dense
        filled
        item-text="label"
        item-value="value"
        required
        :rules="objectRules"
    ></v-select>
            <v-text-field
            v-else
    :loading="loading"
      v-model="form.output"
      :counter="8"
      :rules="floatRules"
      :label="outputLabel"
      required
    ></v-text-field>
                </v-col>
                <v-col>
    <v-select
        v-if="!loading"
        v-model="form.output_param"
        :items="paramOptions"
        :label="paramLabel"
        dense
        filled
        item-text="label"
        item-value="value"
        required
        :rules="objectRules"
    ></v-select>
                </v-col>
                <v-col>
    <v-text-field
    :loading="loading"
      v-model="form.remarks"
      :counter="100"
      
      :label="$store.getters.getTextMap().remarks"
      required
    ></v-text-field>
                </v-col>
            </v-row>
</v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
           {{ $store.getters.getTextMap().write}}
          </v-btn>
          <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
           {{ $store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'writeProductionLog',
    props:['productionLog'],
    components:{
        InfoAlert
    },
    mounted() {
       
    },
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
            menu:null,
            menu2:null,
            menu3:null,
            menu1:null,
            form:{
                param:null,
                output:null,
                input:null,
                remarks:null,
                fromTime:null,
                toTime:null,
                date:null,
            },
            objectRules:[
                v => !!v || 'Required',
            ],
            floatRules:[
                v => !!v || 'Required',
                v => (v && !isNaN(Number(v))) || 'It should be a number',
                v => (v && v>0 )|| 'It should be more than zero',
                v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
            ],
            logRules: [
                v => !!v || 'Required',
                v => /\S+/.test(v) || 'Required',
                v => (v && v.length <= 100 && v.length > 0) || 'Log must be less than 100 characters',
            ],
        }
    },
    computed:{
      inputOptions(){
        let op=[]
        if(this.productionLog && this.productionLog.input_type=='list' && this.productionLog.input_meta){
          let lst=this.$store.getters.getListDataById(this.productionLog.input_meta)
          for(let i of lst){
            op.push({
              label:i.tag,
              value:i.value
            })
          }

        }
        return op
      },
      outputOptions(){
        let op=[]
        if(this.productionLog && this.productionLog.output_type=='list' && this.productionLog.output_meta){
          let lst=this.$store.getters.getListDataById(this.productionLog.output_meta)
          for(let i of lst){
            op.push({
              label:i.tag,
              value:i.value
            })
          }

        }
        return op
      },
        paramOptions(){
            let op=[]
            if(this.productionLog && this.productionLog.output_params){
            if(this.productionLog.output_param_type=="computed"){
                for(let i of this.productionLog.output_params){
                    let v=this.$store.getters.getComputedParameterById(i)
                    if(v && v.device_id){
                      let dev=this.$store.getters.getDeviceById(v.device_id)
                      op.push({
                          label:dev.name+' - '+v.name,
                          value:i
                      })
                    }else{
                      op.push({
                          label:i,
                          value:i
                      })
                    }
                }
            }else if(this.productionLog.output_param_type=="parameter"){
                for(let i of this.productionLog.output_params){
                    let v=this.$store.getters.getParameterById(i)
                    if(v && v.device_id){
                      let dev=this.$store.getters.getDeviceById(v.device_id)
                      op.push({
                          label:dev.name+' - '+v.name,
                          value:i
                      })
                    }else{
                      op.push({
                          label:i,
                          value:i
                      })
                    }
                    
                }
            }
            }
            return op
        },
        fromTimeStamp(){
        if(this.form.date){
          
            let dt=this.form.date.toString()
            if(this.form.fromTime){
              dt+=' '+this.form.fromTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.to_date){
          let dt=this.form.to_date.toString()
            if(this.form.toTime){
              dt+=' '+this.form.toTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));

        }else if(this.form.date){
          
            let dt=this.form.date.toString()
            if(this.form.toTime){
              dt+=' '+this.form.toTime.toString()
              return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      inputLabel(){
          if(this.productionLog && this.productionLog.input){
              return "Input - "+this.productionLog.input
          }else{
              return 'Input'
          }
      },
      outputLabel(){
          if(this.productionLog && this.productionLog.output){
              return "Output - "+this.productionLog.output
          }else{
              return 'Output'
          }
      },
      paramLabel(){
          if(this.productionLog && this.productionLog.output_param_name){
              return this.productionLog.output_param_name
          }else{
              return 'Machine'
          }
      },
        
    },
    methods: {
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
                //console.log("entered")
                this.loading=true
            //evt.preventDefault()
            this.form.log_id=this.productionLog.log_id
            this.form.timestamp=this.fromTimeStamp
            this.form.from_timestamp=this.fromTimeStamp
            this.form.to_timestamp=this.toTimeStamp
            this.form.to_date=this.form.date
            let d=Object.assign({}, this.form)
            //let items=Object.assign({}, this.form.id)
            //d['parameter']=this.parameter.parameter
            //console.log(d)
            
            axios.post(this.$store.state.api+'writeProductionLog',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        //this.$store.dispatch('refreshParameters')
        //console.log("Parameter added "+d)
        
        this.loading=false
        this.info="Log Written"
        this.showDismissibleAlert=true
            //this.dialog=false
            this.$refs.groupForm.reset()
            this.$emit('close')
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.groupForm.reset()
            this.$emit('close')
        }
    },
}
</script>