<template>
    <div  :dark="$store.getters.getColorPalette().isDark" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
        <div class="py-0"  :dark="$store.getters.getColorPalette().isDark" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
          
          
          <v-form
    ref="logForm"
    v-model="valid">
      <v-card flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode" :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentCode}">
        <v-card-title class="headline text-capitalize" :dark="$store.getters.getColorPalette().isDark">
          <span :style="{ color:$store.getters.getColorPalette().accentCode}">{{mode}} {{$store.getters.getTextMap().capacity_logger}}</span>
        </v-card-title>
        <v-card-text>
            <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
                <v-row>
                    <v-col>
                      <v-skeleton-loader
                        v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-text-field :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.name"
                    :counter="30"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().name"
                    required
                    ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{mode}}
          </v-btn>
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
           {{ $store.getters.getTextMap().cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
          </v-form>
        </div>
    </div>

</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateCapacityLog',
    props:['capacityLog'],
    components:{
        InfoAlert
    },
    mounted(){
      if(this.capacityLog){
        this.initFlag=true
        
        let d=Object.assign({},this.capacityLog)
        this.form=d
        this.mode='edit'
        
      }
    },
    data() {
        return {
          initFlag:false,
          energyInitFlag:false,
            showDismissibleAlert:false,
            info:'',
            loading:false,
            workspace:null,
            dev:null,
            energyWorkspace:null,
            energyDev:null,
            valid:false,
            output_param_type:null,
            energy_param_type:null,
            mode:this.$store.getters.getTextMap().create,
            form:{
                name:null,
                
            },
            
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
            ],
            columnRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 18 && v.length > 0) || 'Name must be less than 18 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => !isNaN(v) || 'It must be a Number',
                v => ( v && v.toString().length <= 12 && v.toString().length > 0) || 'Number must be less than 13 characters',
            ],
            unitRules:[
                v => !!v || 'Unit is required',
                v => /\S+/.test(v) || 'Unit is required',
                v => (v && v.length <= 8) || 'Unit length is invalid'
            ],
            outputMetaRules:[
                v => (!!v ||  this.form.output_type=='text') || 'Required',
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
            listRules:[
                v=> !!v || 'Required',
                v => (v && v.length>0) || 'You have to select something',
            ],
        }
    },
    computed:{},
    methods: {
        onSubmit(){
            this.$refs.logForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            let api='createCapacityLog'
            if(this.mode && this.mode=='edit'){
              api='updateCapacityLog'
            }
            axios.post(this.$store.state.api+api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          this.$store.dispatch('refreshCapacityLogs')
          this.loading=false
          this.onReset()
      }else{
          this.info = response.data.msg;
  
          this.showDismissibleAlert = true;
      
      this.loading=false
      }}).catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
      this.loading=false
      })
            }
        },
        onReset(){
            //this.$refs.logForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
        
    },
    watch:{
        
    }
}
</script>
<style scoped>

.v-application .headline{
  color:white
}


</style>