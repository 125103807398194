<template>
        <v-container fluid :color="$store.getters.getColorPalette().backgroundColorCode">
          <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-row>
                <v-col></v-col>
                <v-spacer></v-spacer>
                <v-col align="right" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
                    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-icon
        small
        class="mr-2"
        @click="editDialog=!editDialog"
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])"
       color="blue"
      >
        mdi-pencil
      </v-icon>
        <DeleteConfirmation  v-on:confirm="deleteProductionLog()"  title="Delete Confirmation"  description="Are you sure you want to delete this logger?">
      <v-icon
        small
       
     :color="$store.getters.getColorPalette().deletebtnColor"
      >
        mdi-delete
      </v-icon>
    </DeleteConfirmation>
    </span>
                </v-col>
            </v-row>
            <v-row v-if="editDialog">
              <v-col cols="12"><CreateProductionLog :productionLog="productionLog" v-on:close="editDialog=false"/></v-col>
            </v-row>
            
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12">
                
                <slot name="top"></slot>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-form
    ref="usageForm"
    v-model="valid"
    
  >
        <v-row>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
                v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            :label="$store.getters.getTextMap().dates"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          range
          
        ></v-date-picker>
      </v-menu>
            </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="fetchLogs"
          >
          {{$store.getters.getTextMap().view_logs}}

          </v-btn>
          
            </v-col>
        </v-row>
        </v-form>
            <v-form
    ref="filterForm"
    v-model="filterValid"
    
  >
  <v-row>
    <v-col>
      <v-select
    v-if="!filterLoading"
          v-model="filterForm.param"
          :items="filterParamOptions"       
          :label="$store.getters.getTextMap().param"
         :rules="objectRules"
         required
          
          clearable
        ></v-select>
    </v-col>
    
    <v-col>
      <v-text-field
                        :loading="filterLoading"
                        v-model="filterForm.value"
                        :counter="11"
                        :rules="objectRules"
                        :label="$store.getters.getTextMap().target_value"
                        required
                    ></v-text-field>
    </v-col>
    <v-col>
      <v-progress-circular
      indeterminate
      v-if="filterLoading"
      color="primary"
    ></v-progress-circular>
      <v-btn
          v-if="!filterLoading"
            color="green darken-3"
            outlined
            @click="addFilter"
          >
          {{$store.getters.getTextMap().add_filter}}

          </v-btn>
    </v-col>
  </v-row>
    </v-form>
    
    <v-row v-if="filters.length>0">
      <v-col>
        <span><strong>{{$store.getters.getTextMap().filters}} : </strong></span>
        <v-chip
      class="ma-2"
      close
      @click:close="deleteFilter(filter.key)" 
      color="indigo"
      text-color="white" v-for="filter in filters"
        :key="filter.key">
        {{filter.param}} {{filter.operator}} {{filter.value}}
        </v-chip>
      </v-col>
    </v-row>
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    <v-form
    ref="groupForm"
    v-model="groupValid">
    <v-row v-if="graphData.length>0">
      
      <v-col>
      <v-select
          v-if="!loading"
          v-model="groupBy"
          :items="groupByOptions"
          :label="$store.getters.getTextMap().group_by"
          dense
          filled
          item-text="label"
          item-value="value"
          required
          :rules="objectRules"
      ></v-select>
      </v-col>
      <v-col>
        <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="groupData"
          >
          {{$store.getters.getTextMap().generate_trend}}

          </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="groupedData && groupedData.length>0">
      <v-col cols="12">
        <MultiAxisBarGraphByLabel xLabelKey="label" :label="productionLog.name +' Trend'" :graphData="groupedData" :dataKeys="trendDataKeys" />
      </v-col>
    </v-row>
    </v-form>
    <v-row v-if="graphData.length>0">
      <v-col v-if="productionLog.calculate_production && productionLog.calculate_energy" >
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().max_sec}}
</strong>
           <br>
            <span class="text-h3"> {{Math.round(total.max_sec*100)/100}}</span>{{productionLog.energy_unit+'/'+productionLog.prod_unit}} 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      <v-col v-if="productionLog.calculate_energy">
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().total_energy_consumed}}</strong>
           <br>
            <span class="text-h3"> {{Math.round(total.energy*100)/100}}</span>{{productionLog.energy_unit}} 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      <v-col v-if="productionLog.calculate_production">
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().total_production}}</strong>
           <br>
            <span class="text-h3"> {{Math.round(total.production*100)/100}}</span>{{productionLog.prod_unit}} 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
          
    </v-row>
    <v-row v-if="graphData.length>0">
      <v-col v-if="productionLog.calculate_production && productionLog.calculate_energy" >
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().average_sec}}</strong>
           <br>
            <span class="text-h3"> {{Math.round(total.sec*100)/100}}</span>{{productionLog.energy_unit+'/'+productionLog.prod_unit}} 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      <v-col v-if="productionLog.calculate_production">
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().average_production}}</strong>
           <br>
            <span class="text-h3"> {{Math.round(total.avg_prod*100)/100}}</span>{{productionLog.prod_unit}} 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-col v-if=" productionLog.calculate_energy">
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().average_energy_consumed}}</strong>
           <br>
            <span class="text-h3"> {{Math.round(total.avg_energy*100)/100}}</span>{{productionLog.energy_unit}} 
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
    </v-row>
    <v-row v-if="graphData.length>0">
            <v-col cols="12">
                <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      item-key="timestamp"
      :search="search"
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      :loading="loading"
    loading-text="Loading... Please wait"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{$store.getters.getTextMap().production_logs}}
 - {{dateRangeText}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn 
                v-if="graphData.length>0"
                outlined 
              :color="$store.getters.getColorPalette().downloadbtnColor"
                @click="downloadPDF">
                {{$store.getters.getTextMap().download_table}}

                </v-btn>
        <a ref="csvDownload" hidden></a>
      </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <DeleteConfirmation  v-on:confirm="deleteLogData(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this logger?">  
        <v-icon small color="red">mdi-delete</v-icon>
        </DeleteConfirmation>
       
      </td>
    </template>
          
          
    </v-data-table>
  </v-card>
            </v-col>
        </v-row>
        </v-container>
    
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import CreateProductionLog from '@/components/crud_components/CreateProductionLog'
import DataProcessingUtillities from '@/utillities/DataProcessingUtillities'
import MultiAxisBarGraphByLabel from '@/components/graphs/MultiAxisBarGraphByLabel'
import moment from 'moment'
import axios from 'axios'
// import WriteProductionLog from '@/components/crud_components/WriteProductionLog'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import { applyPlugin } from 'jspdf-autotable'
import DeleteConfirmation from '@/components/DeleteConfirmation'
// applyPlugin(jsPDF)
export default {
    name:'ProductionLogger',
    props:['productionLog'],
    components:{
      CreateProductionLog,
        // WriteProductionLog,
        InfoAlert,
        MultiAxisBarGraphByLabel,
        DeleteConfirmation
    },
    mounted() {
        this.form.date=[]
        this.form.date[0]=moment().subtract(18,'d').format('YYYY-MM-DD')
        this.form.date[1]=moment().subtract(1,'d').format('YYYY-MM-DD')
        this.fetchLogs()
    },
    data(){
        return {
          showDialog:false,
            isActive:false,
            editDialog:false,
            
            dialog:false,
            form:{
                date:null,
                log_id:null
            },
            groupBy:null,
            //date: new Date().toISOString().substr(0, 10),
            expanded: [],
            search:'',
            valid:false,
            groupValid:false,
            menu:false,
            graphData:[],
            groupedData:[],
            showDismissibleAlert:false,
            loading:false,
            info:'',
            filters:[],
            filterLoading:false,
            filterValid:false,
            filterForm:{
              param:null,
              operator:'==',
              value:null
            },
            filterParamOptions:[
              { text: 'Date', value: 'date' },
              { text: 'From Time', value: 'fromtime' },
          { text: 'To Time', value: 'totime' },
          { text: 'Input', value: 'input' },
          { text: 'Output', value: 'output' },
          { text: 'Machine', value: 'machine' },
          { text: 'Remarks', value: 'remarks' },
            ],
             objectRules:[
                v => !!v || 'Required',
            ],
            headers: [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
         // {text:'timestamp',value:'timestamp'},
          { text: 'From (HRS)', value: 'fromtime' },
          { text: 'To (HRS)', value: 'totime' },
          { text: 'Input', value: 'input' },
          { text: 'Output', value: 'output' },
          { text: 'Machine', value: 'machine' },
          { text: 'Remarks', value: 'remarks' },
        ],
        groupByOptions:[
          {label:'Output',value:'output'},
          {label:'Input',value:'input'},
          {label:'From Date',value:'date'},
          {label:'To Date',value:'to_date'},
          { label: 'From Time', value: 'fromtime' },
        ]
        }
    },
    computed:{
        dateRangeText () {
          if(this.form.date==null){
            return null
          }
        return this.form.date.join(' - ')
      },
      
      fromTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[0].toString()
            if(Number(moment(dt,'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"))){
              dt=this.form.date[1].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X"));
          }
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.date){
          if(this.form.date.length>1 ){
            let dt=this.form.date[1].toString()
            if(Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').format("X")) > Number(moment(dt,'YYYY-MM-DD').format("X"))){
              dt=this.form.date[0].toString()
            }
            return Number(moment(dt,'YYYY-MM-DD').add(1,'d').format("X"));
          }else{
            return Number(moment(this.form.date[0].toString(),'YYYY-MM-DD').add(1,'d').format("X"));
          }
        }else{
          return null
        }
      },
      inputLabel(){
          if(this.productionLog && this.productionLog.input){
              return "Input - "+this.productionLog.input
          }else{
              return 'Intput'
          }
      },
      outputLabel(){
          if(this.productionLog && this.productionLog.output){
              return "Output - "+this.productionLog.output
          }else{
              return 'Output'
          }
      },
      paramLabel(){
          if(this.productionLog && this.productionLog.output_param_name){
              return this.productionLog.output_param_name
          }else{
              return 'Machine'
          }
      },
      productionLabel(){
          if(this.productionLog.calculate_production){
          let prd='Production'
          if(this.productionLog.prod_unit && this.productionLog.prod_unit!=''){
            prd+=' ('+this.productionLog.prod_unit+')'
          }
          return prd

        }else{
          return ''
        }
      },
      energyLabel(){
          if(this.productionLog.calculate_energy){
          let prd='Energy'
          if(this.productionLog.energy_unit && this.productionLog.energy_unit!=''){
            prd+=' ('+this.productionLog.energy_unit+')'
          }
          return prd

        }else{
          return ''
        }
      },
      secLabel(){
          if(this.productionLog.calculate_energy && this.productionLog.calculate_production){
          let prd='SEC'
          if(this.productionLog.energy_unit && this.productionLog.energy_unit!='' && this.productionLog.prod_unit && this.productionLog.prod_unit!=''){
            prd+=' ('+this.productionLog.energy_unit+'/'+this.productionLog.prod_unit+')'
          }
          return prd

        }else{
          return ''
        }
      },
      trendDataKeys(){
        let dataKeys=[]
        if(this.productionLog.calculate_production){
          
          dataKeys.push('production')

        }
        if(this.productionLog.calculate_energy){
          dataKeys.push('energy')

        }
        if(this.productionLog.calculate_production && this.productionLog.calculate_energy){
          dataKeys.push('sec')

        }
        return dataKeys
      },
      filteredData(){
        let filteredData=this.graphData
        for(let i of this.filters){
          let filterFunc=()=>{
          }
          switch (i.operator) {
               case '>=': filterFunc=(data)=>{return data[i.param]>=i.value};
               break;
               case '>': filterFunc=(data)=>{return data[i.param]>i.value};
               break;
               case '==': filterFunc=(data)=>{return data[i.param]==i.value};
               break;
               case '<=': filterFunc=(data)=>{return data[i.param]<=i.value};
               break;
               case '<': filterFunc=(data)=>{return data[i.param]<=i.value};
               break;
          }
          filteredData=filteredData.filter(filterFunc);
        }
        return filteredData
      },
      total(){
        let a={
            'production':0,
            'energy':0,
            'sec':0,
            'avg_prod':0,
            'avg_energy':0,
            'max_sec':0,
          }
        if(this.filteredData && this.filteredData.length>0){
          
          let count=0
          for(let i of this.filteredData){
            if (Number(i['sec'])>0){
              a['production']+=Number(i['production'])
              a['energy']+=Number(i['energy'])
              a['sec']+=Number(i['sec'])
              if(a['max_sec']<Number(i['sec'])){
                a['max_sec']=Number(i['sec'])
              }
              count++
            }
          }
          if(count>0){
            if(a['production']&&a['energy']){
              a['sec']=a['energy']/a['production']
            }else{
              a['sec']/=count
            }
          a['avg_prod']=a['production']/count
          a['avg_energy']=a['energy']/count
          return a
          }
        }
          return a
        
      }
    },
    methods: {
       cancel(){
          this.showDialog=false
        },
        confirm(){

          this.showDialog=false
        },
      addFilter(){
        this.$refs.filterForm.validate()
            if(this.filterValid){
              let d=Object.assign({}, this.filterForm)
              d.key=Number(moment().format("X"))
              this.filters.push(d)
              this.$refs.filterForm.reset()
            }
      },
      deleteFilter(key){
        this.filters=this.filters.filter(
      function(data){ return data.key != key }
  );
      },
        deleteProductionLog(){

          axios.post(this.$store.state.api+'deleteProductionLog',this.productionLog,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
          }}).then(response=>{
            if(response.data.status==='success'){
              this.info = 'Logger Deleted successfully';
              
              this.showDismissibleAlert = true;
              this.$store.dispatch('refreshProductionLogs')
            }else{
             
              this.info = response.data.msg;
              
              this.showDismissibleAlert = true;
              
            }
      this.loading=false

            
          }).catch(err=>{
      console.log(err)
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

        },
        updateTableHeader(){
            this.headers= [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
          { text: 'From (HRS)', value: 'fromtime' },
          { text: 'To Date', value: 'to_date' },
          { text: 'To (HRS)', value: 'totime' },
          { text: this.inputLabel, value: 'input' },
          { text: this.outputLabel, value: 'output' },
          { text: this.paramLabel, value: 'machine' },
          { text: 'Remarks', value: 'remarks' },
          
        ]
        
          this.headers.push({ text: this.productionLabel, value: 'production' })

        
        if(this.productionLog.calculate_energy){
          this.headers.push({ text: this.energyLabel, value: 'energy' })

        }
        if(this.productionLog.calculate_production && this.productionLog.calculate_energy){
          this.headers.push({ text: this.secLabel, value: 'sec' })

        }


        },
        downloadPDF(){

            // let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Date','From (HRS)','To (HRS)',this.inputLabel, this.outputLabel, this.paramLabel,"Remarks"]
            if(this.productionLog.calculate_energy){
                  header.push(this.energyLabel)
                  
                }
                if(this.productionLog.calculate_production){
                  header.push(this.productionLabel)
                  
                }
                if(this.productionLog.calculate_production && this.productionLog.calculate_energy){
                  header.push(this.secLabel)
                  
                }
            let body=[]
            for (let i of this.graphData){
                let temp=[]
                temp.push(i.date)
                temp.push(i.fromtime)
                temp.push(i.totime)
                temp.push(i.input)
                temp.push(i.output)
                /*
                if(this.productionLog){


              if(this.productionLog.output_param_type=="computed"){
                  let v=this.$store.getters.getComputedParameterById(i.output_param)
                  let dev=this.$store.getters.getDeviceById(v.device_id)
                  temp.push(dev.name+' - '+v.name)
              }else if(this.productionLog.output_param_type=="parameter"){
                  let v=this.$store.getters.getComputedParameterById(i.output_param)
                  let dev=this.$store.getters.getParameterById(v.device_id)
                  temp.push(dev.name+' - '+v.name)
              }else{
                  temp.push("Unknown")
              }
              }else{
                temp.push(i.param)
              }*/
              temp.push(i.machine)
                temp.push(i.remarks)

                if(this.productionLog.calculate_energy){
                  
                  temp.push(i.energy)
                }
                if(this.productionLog.calculate_production){
                  
                  temp.push(i.production)
                }
                if(this.productionLog.calculate_production && this.productionLog.calculate_energy){
                  
                  temp.push(i.sec)
                }
                body.push(temp)
            }
            // pdf.autoTable({
            //     head: [header],
            //     body: body
            //     })
            // pdf.save(this.productionLog.name+'_'+this.dateRangeText+'.pdf');

            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.productionLog.name+'_'+this.dateRangeText + '.csv';
            hiddenElement.click();

      },
      groupData(){
        this.$refs.groupForm.validate()
        if(this.groupValid){
          this.groupedData=[]
          let g=DataProcessingUtillities.groupBy(this.filteredData,this.groupBy)
          for(let i in g){
            let energy=0
            let production=0
            let sec=0
            for(let j of g[i]){
              if(j['energy']){
                energy+=j['energy']
              }
              if(j['production']){
                production+=j['production']
              }
            }
            if(energy && production){
              sec=energy/production
            }
            this.groupedData.push({label:i,energy:energy, production:production, sec:sec})
          }
        }
        },
        deleteLogData(logData){
          this.loading=false
          axios.post(this.$store.state.api+'deleteProductionLogData',logData,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
          }}).then(response=>{
            if(response.data.status==='success'){
              this.info = 'Entry Deleted successfully';
              
              this.showDismissibleAlert = true;
              this.fetchLogs()
            }else{
             
              this.info = response.data.msg;
              
              this.showDismissibleAlert = true;
              
            }
      this.loading=false

            
          }).catch(err=>{
      console.log(err)
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })
        },
          fetchLogs(){
            this.$refs.usageForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  log_id:this.productionLog.log_id,
      from_timestamp:this.fromTimeStamp,
      to_timestamp:this.toTimeStamp
    }
    let endpoint='getproductionLogDataBetweenTimestamp'
    //console.log(fromTimeStamp)
    
    axios.post(this.$store.state.api+endpoint,payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      //console.log("report data received")
      //console.log(response.data)
      if(response.data.status==='success'){
          this.updateTableHeader()
            let data=[]
            ///this.search=response.data.data
            for (let i of response.data.data){
              //this.info=response.data.data
              if(this.productionLog && this.productionLog.output_param_type && i.output_param){
                if(this.productionLog.output_type=="list" && this.productionLog.output_meta){
                  //console.log(this.$store.getters.getListDataById(this.productionLog.output_meta))
                    let listData=this.$store.getters.getListDataById(this.productionLog.output_meta).find(x=>x.value === i.output)
                    //console.log(listData)
                    if(listData){
                      i.output=listData.tag
                    }
                  }
              if(this.productionLog.output_param_type=="computed"){
                  let v=this.$store.getters.getComputedParameterById(i.output_param)
                  let dev=this.$store.getters.getDeviceById(v.device_id)
                  i.machine=dev.name+' - '+v.name
              }else if(this.productionLog.output_param_type=="parameter"){
                //console.log(i.output_param)
                  let v=this.$store.getters.getParameterById(i.output_param)
                  console.log(v.device_id)
                  let dev=this.$store.getters.getDeviceById(v.device_id)
                  i.machine=dev.name+' - '+v.name
              }else{
                  i.machine="unknown"
              }
              }else{
                  i.machine=i.output_param
              }
              i.input=Number(i.input)
              if(i.energy){
                i.energy=Math.round(Number(i.energy)*1000)/1000
              }
              if(i.production){
                i.production=Math.round(Number(i.production)*1000)/1000
              }
              if(i.sec){
                i.sec=Math.round(Number(i.sec)*1000)/1000
              }
              if(i.to_date){
                i.to_date=moment(i.to_date).format('YYYY-MM-DD')
              }
              if(i.date){
                i.date=moment(i.date).format('YYYY-MM-DD')
              }
              
                data.push(i)
            
            }
            this.graphData=data
                //console.log(response.data.data)
                //this.report['parameter']=this.form.parameter
                //this.report['date']=this.form.date
                if(this.graphData.length==0){
                    this.info = "No data to show";
                        this.showDismissibleAlert = true;
                }

    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
      console.log(err)
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

        }
    },
        
    },
}
</script>